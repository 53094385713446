<style>
  .tangy-main-container {
    left: 0px;
    position: relative !important
  }

  mat-toolbar,
  mat-tab-header {
    display: none !important;
  }
</style>
<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div class="device-list-btn">
  <button mat-raised-button color="warn" (click)="export()" [disabled]="isExporting">{{'Export Data Definition'|translate}}</button>
</div>
<div class="tangy-content tangy-full-width">
  <mat-card>
    <mat-card-title>
      <div id="container" #container></div>
      <table class="card-title" *ngIf="myForm">
        <tr>
          <td>{{'Group'|translate}}</td>
          <td>{{groupDetails?.label}}</td>
        </tr>
        <tr>
          <td>{{'Group ID'}}</td>
          <td>{{groupDetails?._id}}</td>
        </tr>
        <tr>
          <td>{{'Form Title'|translate}}</td>
          <td>{{myForm?.title}}</td>
        </tr>
        <tr>
          <td>{{'Form ID'|translate}}</td>
          <td>{{myForm?.id}}</td>
        </tr>
      </table>
    </mat-card-title>
    <br>
    <div>
      <table class="tangy-full-width">
        <th>Section ID</th>
        <th>Variable Name</th>
        <th>Prompt</th>
        <th>Type</th>
        <th>Hint Text</th>
        <th>Options</th>
        <th>Required</th>
        <th>Disabled</th>
        <th>Hidden</th>
        <th></th>
        <ng-container *ngFor="let section of meta?.items;let index= index">
          <tr *ngFor="let input of section.inputs">
            <td>{{section.id}}</td>
            <td>{{input.name}}</td>
            <td>{{input.label}}</td>
            <td>
              {{input.tagName==='TANGY-SELECT'?'single':''}}
              {{input.tagName==='TANGY-RADIO-BUTTONS'?'single':''}}
              {{input.tagName==='TANGY-CHECKBOX'?'single':''}}
              {{input.tagName==='TANGY-CHECKBOXES'?'multiple':''}}
              {{input.type}}
            </td>
            <td>{{input.hintText}}</td>
            <td>
              <span *ngIf="input.value.length>0">
                <span *ngFor="let option of input.value; let index=index">
                  {{option.value}} {{'"'+option.label+'"'}}{{index+1===input.value.length?'':' ,'}}
                </span>
              </span>
            </td>
            <td>{{input.required}}</td>
            <td>{{input.disabled}}</td>
            <td>{{input.hidden}}</td>
          </tr>
        </ng-container>
        </table>
<!--        <br>-->
<!--        <hr>-->
<!--        <br><br>-->
    </div>
  </mat-card>
</div>